.ant-avatar-image{
    width: 65px;
    height: 65px;
}

.body-cardprofile{
    margin-left: 5px;
}

.ant-image-mask-info{
    height: 50%;
}


.ant-badge-count, .ant-badge-dot, .ant-badge .ant-scroll-number-custom-component {
    position: absolute !important;
    top: 0 !important;
    right: 0 !important;
    transform: translate(-124%, 105%)!important;
    transform-origin: 27% 0%!important;
}