.section-body {
    display: flex;
    justify-content: space-around;
}

.titulo, .descripcion {
    display: flex;
    align-items: center;
    justify-content: space-around;
}


#form .form-group {
    margin-bottom: 0rem !important;
}

.descripcion {
    text-align: justify;
}
.titulo{
    font-size: 22px;
}
.div[class='item-']{ 
    display: flex;
    justify-content: space-around;
    flex-direction: column;
}
/* ----------- FORMULARIO DE INDUCCION ------------- */
.form-induccion{
    background: white;
    padding: 2rem 1.2rem;
    margin-top: 2rem;
    border-radius: 4px;
}
.card-pregunta{
    border: 1px solid #80808045;
    border-radius: 4px;
    padding:1rem;
    margin-bottom: 1.5rem;
}
.card-enviar{
    margin-top: 3rem;
}
.btn-enviar{
    padding: 7px 38px;
    font-size: 14px;
}
.card .card-body {
    box-shadow: 0 2.5px 2.3px -7px rgba(0,0,0,.019),0 10px 6.3px 2px rgba(0,0,0,.103),0 38.7px 15.1px -12px rgba(0,0,0,-9.819),0 57px 50px 16px rgba(0,0,0,.0)!important;
    top: -12px;
    padding-top: 20px;
    padding-bottom: 20px;
}
.card .card-header h4 {
    font-size: 22px;
}
.swal2-styled.swal2-confirm{
    background-color: transparent !important;
    color: #287233 !important;
    border: 1px solid #287233 !important;
}
.swal2-styled.swal2-confirm:focus {
    box-shadow: none !important;
}
.swal2-styled.swal2-default-outline:focus {
    box-shadow: none !important;
}
.swal2-actions:not(.swal2-loading) .swal2-styled:active {
    background-image: transparent !important;
}
.swal2-actions:not(.swal2-loading) .swal2-styled:hover {
    background-image: none !important;
}
.background-form{
    box-shadow: 0 2.5px 2.3px -33px rgba(0,0,0,.019),0 10px 6.3px -33px rgba(0,0,0,.103),0 39.7px 15.1px -33px rgba(0,0,0,.181),0 57px 50px -33px rgba(0,0,0,.2)!important;
    top: -12px !important;
    /* box-shadow: 0 4px 8px rgb(0 0 0 / 3%); */
    background-color: white;
    border-radius: 3px;
    border: none;
    position: relative;
    padding: 1rem;
}

@media (max-width: 575.98px) { 
    .section .section-title:before {
        content: ' ';
        border-radius: 5px;
        height: 8px;
        width: 12px;
        background-color: var(--red-1);
        display: inline-block;
        float: left;
        margin-top: 6px;
        margin-right: 9px;
    }
    .selectgroup-pills .selectgroup-item {
        width: fit-content;
    }
    .selectgroup-pills {
        display: flex;
        flex-direction: column;
    }
    .card-body {
        padding: 1rem;
    }
 }


 .section .section-title:before {
    font-weight: 500;
    content: " ";
    border-radius: 14px;
    height: 5px;
    width: 13px;
    background-color: var(--red-1);
    display: inline-block;
    /* margin-top: 6px; */
    margin-right: 15px;
}

.section .section-title {
    font-size: 15px !important;
    color: #191d21;
    font-weight: 600;
    position: relative;
    margin: 30px 0 25px 0;
}


.w-100 {
    width: 100%!important;
    margin-bottom: 10px !important;
}
.ant-upload.ant-upload-select-picture-card{
    height: 40px;
}
.ant-picker-ranges{
    text-align: center !important;
}
.ant-picker-now-btn{
    border: 1px solid lightgray;
    padding: 2px 7px;
    border-radius: 3px;
    
}
li.ant-picker-now{
    margin: 5px 0;
}
.ant-picker-ranges .ant-picker-ok {
    float: none;
    margin-left: 0;
}