.icon {
    margin-left: -28px;;
    cursor: pointer;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.grupo {
    display: inline !important;
    /* margin-right: 16px; */
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    /* background-clip: padding-box; */
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.form-group{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.btn-primary {
    color: #fff;
    background-color: #c00f10 !important;
    border-color: #d05758 !important;
}

.simple-footer {
    font-size: small;
}



