.image {  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 20vh;
  height: 20vh;
  z-index: 200001;

}
.image::before {  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 20vh;
  height: 20vh;
  z-index: 200001;
}

#img-spinner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  
}

/* body {
 
} */

.spin {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;  display: inline-block;
  width: 20vh;
  height: 20vh;
  border: 2px solid #1d2126;
  border-radius: 100%;
  animation: spin 0.75s infinite linear;

}

.spin::before, .spin::after {
  left: -2px;
  top: -2px;
  display: none;
  position: fixed;
  content: '';
  width: inherit;
  height: inherit;
  border: inherit;
  border-radius: inherit;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinner, .spinner::before, .spinner::after {
  display: inline-block;
  border-color: transparent;
  border-top-color: rgb(123, 123, 123);
  animation-duration: 2.2s;
}

.spinner::before {
  transform: rotate(120deg);
}

.spinner::after {
  transform: rotate(240deg);
}

/* Transparent Overlay */

.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(135, 135, 135, 0.516), rgba(65, 65, 65, 0.496));
  z-index: 20000;
  background: -webkit-radial-gradient(rgba(130, 130, 130, 0.516), rgba(104, 104, 104, 0.496));
  backdrop-filter: blur(0.5px);
  -webkit-filter: blur(0.5px);


}

.loading-2:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--gray-10);
  z-index: 50;
  background: var(--gray-10);
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(360deg);
  }
}