:root {
	--red-1: rgb(213, 15, 24);
	--red-2: rgb(221, 55, 43);
	--red-3: rgb(229, 80, 62);
	--red-4: rgb(236, 103, 82);
	--red-5: rgb(242, 125, 103);
	--red-6: rgb(247, 146, 125);
	--red-7: rgb(251, 167, 149);
	--red-8: rgb(254, 189, 174);
	--red-9: rgb(255, 210, 200);
	--red-10: rgb(255, 232, 227);
	--blue-1: rgb(13, 52, 69);
	--blue-2: rgb(108, 135, 209);
	--green-1: rgb(80, 182, 140);
	--green-2: rgb(99, 201, 159);
	--green-3: rgb(115, 217, 175);
	--green-4: rgb(135, 237, 195);
	--gray-1: rgb(10, 10, 10);
	--gray-2: rgb(28, 28, 28);
	--gray-3: rgb(30, 30, 30);
	--gray-4: rgb(40, 40, 40);
	--gray-5: rgb(130, 130, 130);
	--gray-6: rgb(143, 143, 143);
	--gray-7: rgb(165, 165, 165);
	--gray-8: rgb(215, 215, 215);
	--gray-9: rgb(244, 244, 244);
	--gray-10: rgb(246, 246, 246);
    --text-1: #ffffff;
    --text-2: #000000;
	--shadow: -3px 5px 16px 1px rgba(0,0,0,0.53);;
	--shadow-2: -3px 5px 16px 1px rgba(0,0,0,0.35);
	--shadow-3: -3px 5px 16px 1px rgba(0, 0, 0, 0.152);
}